import React, { Component } from "react"
import Formsy from 'formsy-react';
import Collapsible from 'react-collapsible';
import { connect } from "react-redux"

import closeChat from "../images/close-icon.svg"
import failImg from "../images/opps.img.svg"

import "../assets/css/circle.css"

import MyInputMask from "../components/MyInputMask";
import { navigate } from "gatsby"
import * as actionCreators from './../store/actions';

import Layout from "../components/layout"
import Wizard from "../components/wizard"
import customizeIcon from "../images/gray/3.svg"
import rightmark from "../images/check.svg"
import forbiddenIcon from "../images/forbidden-mark.svg"
import arrowDown from "../images/arrow-down.svg"

import planimg1 from "../images/motor.svg"
import planimg4 from "../images/motor.svg"
import planimg2 from "../images/transmission.svg"
import planimg3 from "../images/axle.svg"
import planimg10 from "../images/ice-crystal.svg"
// import airConIcon from "../images/ice-crystal.svg"
import planimg9 from "../images/power.svg"
import planimg8 from "../images/brake-disc.svg"
import planimg7 from "../images/suspension.svg"
import planimg6 from "../images/steering-wheel.svg"
import planimg5 from "../images/engine.svg"
import planimg11 from '../images/covered/car-light.png';
import planimg12 from '../images/covered/speedometer.png';
import planimg13 from '../images/covered/accumulator.png';
import infoicon from "../images/gray/1.svg";

import SEO from "../components/seo"
import ButtonLoader from "../components/buttonLoader";
import { restrictRouteForStep } from "../core/util";
import { planData } from "../components/CoveragePlan";

const iconObj = {
    planimg1, planimg2,
    planimg3,
    planimg4,
    planimg5,
    planimg6,
    planimg7,
    planimg8,
    planimg9,
    planimg10, planimg11, planimg12, planimg13
}

class CustomizePlanPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            canSubmit: false,
            showVehicalInfo: null,
            keepVehicle:60,
            payAtClaimTime: 250,
            levelOfCoverage: '',
            values: [0, 0],
            coveragePlan: 'Engine',
            planIndex : 'plan1',
            monthlyPayment : 0,
            activationCost : 0, 
            covered : 0,
            VIN : '',
            showalert:false,
            showInfo:false,
            termMonth:0,
            termVal:0
        };
    }
    componentDidMount = async() => {
        const { showLoader, hideLoader, step1Data, step3Data } = this.props;
        showLoader();

        //console.log(step1Data);
        let paramId = restrictRouteForStep(this.props.location);
        if (this.props.step2response && !this.props.step2response.userId) {
            //console.log('inside111----------------')
            if (paramId) {
                await this.getFromDirect(paramId);
            }
        } else if(step3Data.keepVehicle){
            //console.log('inside2222----------------')
            let plans = JSON.parse(sessionStorage['plans'+(global.dealertoken?global.dealertoken:'')]);
            var plnidx = 'plan1';
            Object.keys(plans).forEach(function(idx) {
                if(plans[idx]['name'] === step3Data.levelOfCoverage){
                    plnidx = idx;
                }
            });
            //console.log(plans[plnidx]['actual']+'--'+plans[plnidx]['price']);
            var ded=0;
            if(typeof plans[plnidx]['deduct'][step3Data.payAtClaimTime] !== 'undefined'){
                ded = plans[plnidx]['deduct'][step3Data.payAtClaimTime];
            } else if(typeof plans[plnidx]['deduct'][250] !== 'undefined') {
                step3Data.payAtClaimTime = 250;
                ded = plans[plnidx]['deduct'][250];
            }

            var term = 0;
            //console.log(step3Data);
            /* console.log('step3Data.termMonth:'+step3Data.termMonth)
            console.log('1key:'+Object.keys(plans[plnidx]['terms'])[0])
            console.log('amt:'+plans[plnidx]['terms'][step3Data.termMonth]) */


            if(plans[plnidx]['terms'][step3Data.termMonth]){
                term = plans[plnidx]['terms'][step3Data.termMonth]*1;
            } else if(Object.keys(plans[plnidx]['terms'])[0]) {
                step3Data.termMonth = Object.keys(plans[plnidx]['terms'])[0];
                term = plans[plnidx]['terms'][step3Data.termMonth]*1;
            }
            //console.log(plans[plnidx]['price'],ded,term)
            
            this.setState({ 
                monthlyPayment: (plans[plnidx]['price']+ded+term),
                keepVehicle: step3Data.keepVehicle,
                levelOfCoverage: plans[plnidx]['name'],
                payAtClaimTime: step3Data.payAtClaimTime,
                termMonth: step3Data.termMonth,
                covered: plans[plnidx]['covered'],
                values: [step3Data.milesPerMonthMin, step3Data.milesPerMonthMax],
                plans: plans,
                planIndex:plnidx,
                showVehicalInfo: step1Data.vehicleInfo.year+' '+step1Data.vehicleInfo.maker+' '+step1Data.vehicleInfo.model,
                VIN: step1Data.vehicleInfo.vin
            })

            this.setState({ covered: plans[plnidx]['covered'] })
            this.state.plans = plans;
            this.state.covered = plans[plnidx]['covered'];
            this.state.keepVehicle = step3Data.keepVehicle;
            this.state.levelOfCoverage = plans[plnidx]['name'];
            this.state.monthlyPayment = plans[plnidx]['price']+ded+term;
            this.state.payAtClaimTime = step3Data.payAtClaimTime;
            this.state.termMonth = step3Data.termMonth;
            
            //console.log(this.state)
            
            this.backLoad();
            hideLoader();

        } else {
            
            let plans = JSON.parse(sessionStorage['plans'+(global.dealertoken?global.dealertoken:'')]);
            var mpay = 0;
            this.setState({ keepVehicle: 60 })
            this.setState({ levelOfCoverage: plans.plan1.name })
            this.setState({ payAtClaimTime: 250 });

            //console.log(plans['plan1']['terms'])
            //console.log(Object.keys(plans['plan1']['terms'])[0])

            let term1 = Object.keys(plans['plan1']['terms'])[0];
            let term = 0;
            if(term1){
                this.setState({ termMonth: term1 });
                term = plans['plan1']['terms'][term1]*1;
            }

            this.setState({ VIN: step1Data.vehicleInfo.vin})
            this.setState({ showVehicalInfo: step1Data.vehicleInfo.year+' '+step1Data.vehicleInfo.maker+' '+step1Data.vehicleInfo.model})
            
            this.setState({ values: [0, 0] })
            this.setState({ plans: plans})
            this.setState({ planIndex: 'plan1'})
            ded= 0;
            plans['plan1']['deductibles'].forEach(function(tmp) {
                if((tmp.amt*1) === 250){
                    ded = tmp.cost;
                }
            });
            //console.log(plans.plan1.price,ded,term)
            mpay = (plans.plan1.price*1)+(ded*1)+term;
            this.setState({ monthlyPayment: mpay })
            //console.log(mpay)

            this.setState({ covered: plans.plan1.covered })
            this.state.plans = plans;
            this.state.covered = plans.plan1.covered;
            this.state.keepVehicle = 60;
            this.state.levelOfCoverage = plans.plan1.name;
            this.state.monthlyPayment = mpay;
            this.state.payAtClaimTime = 250;

            hideLoader();
            this.backLoad();
        } 
        
        
    }

    getFromDirect = async(paramId) => {
        const { saveSecondStep, saveFirstStep, saveThirdStep, saveFourthStep, saveSecondStepResponse, saveThirdStepResponse, hideLoader } = this.props;
        let uri = process.env.GATSBY_APP_API_BASE_URL+'rest/getPlanById'+(global.dealertoken?'/?dealertoken='+global.dealertoken:'');
        //console.log(uri)
            await fetch(uri,{
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({
                    _id: paramId,
                })
              })
            .then(res => res.json())
            .then((res) => {
                if(res.status == 1){
                    let secondstepResponse = {
                        userId: res.data.step3.userId,
                        vehicleId: res.data.step3.vehicleId,
                        recordId: res.data.step3.recordId
                    }
                    
                    
                    if (res.data.step3) {
                        
                        const step3response = res.data.step3;
                        var step1Data = res.data.step1.vehicleInfo;
                        
                        
                        //console.log(step1Data);
                        this.setState({ keepVehicle: step3response.keepVehicle })
                        this.setState({ levelOfCoverage: step3response.levelOfCoverage })
                        this.setState({ payAtClaimTime: step3response.payAtClaimTime })
                        this.setState({ termMonth: step3response.termMonth })
                    
                        this.setState({ covered: step3response.covered })
                        
                        this.setState({ values: [step3response.milesPerMonthMin, step3response.milesPerMonthMax] })
                        this.setState({ showVehicalInfo: step1Data.year+' '+step1Data.maker+' '+step1Data.model})
                        this.setState({ VIN: step1Data.vin})
                        let plans = res.data.plans;
                        this.setState({ plans: plans})
                        sessionStorage['plans'+(global.dealertoken?global.dealertoken:'')] = JSON.stringify(plans);

                        var plnidx = 0;
                        Object.keys(plans).forEach(function(idx) {
                            if(plans[idx]['name'] === step3response.levelOfCoverage){
                                plnidx = idx;
                            }
                        });
                        this.setState({ monthlyPayment: step3response.monthlyPayment })
                        //this.setState({ activationCost: step3response.activationCost })
                        this.setState({ planIndex: plnidx })
                        step3response.planIndex = plnidx;
                    }
                    saveFirstStep(res.data.step1);
                    saveSecondStep(res.data.step2);
                    saveSecondStepResponse(secondstepResponse);
                    saveThirdStep(res.data.step3);
                    saveThirdStepResponse(res.data.step3);
                    saveFourthStep(res.data.step4)
                    hideLoader();
                } else {
                    navigate("/vehicleInfo/?q="+paramId+(global.dealertoken?'&dealertoken='+global.dealertoken:''))
                }
                    
            });
        
    }

    getQuateData() {
        
    }

    disableButton = () => {
        this.setState({ canSubmit: false });
    }

    enableButton = () => {
        this.setState({ canSubmit: true });
    }

    submit = (model) => {
        model.vehicleInfo.vin = this.props.step1Data.vehicleInfo.vin;
        this.updateVehical(model);
        this.toggleVehicalInfo()
    }

    onChangeQuestion = async(type, value) => {
        if(type==='levelOfCoverage'){
            let plans = this.state.plans;
            let indx = value;
            var ded = 0;
            var tmpamt = this.state.payAtClaimTime;
            
            if(typeof plans[indx]['deduct'][tmpamt] !== 'undefined'){
                ded = plans[indx]['deduct'][tmpamt];
            } else if(typeof plans[indx]['deduct'][250] !== 'undefined') {
                ded = plans[indx]['deduct'][250];
                tmpamt = 250;
            }
            //console.log(ded,tmpamt)
            let term = 0;
            if(this.state.termMonth){
                if(plans[indx]['terms'][this.state.termMonth]){
                    term = plans[indx]['terms'][this.state.termMonth]*1;
                }
            }
             
            var curval =  this.state;
            curval.covered = plans[value]['covered'];
            curval.monthlyPayment = (plans[value]['price'])+(ded*1)+term;
            //curval.activationCost = (plans[indx]['activation']*1)+(ded.act*1);
            curval.levelOfCoverage = plans[value]['name'];
            curval.planIndex = value;
            curval.payAtClaimTime = tmpamt;
            this.setState(curval);
            
        } else if(type === 'keepVehicle'){ 
            this.setState({ 'keepVehicle':   value});
            this.state.keepVehicle = value;
        } else if(type==='payAtClaimTime'){
            let plans = this.state.plans;
            let indx = this.state.planIndex;
            ded = 0;
            plans[indx]['deductibles'].forEach(function(tmp) {
                if((tmp.amt*1) === value){
                    ded = tmp.cost;
                }
            });
            curval =  this.state;
            curval.monthlyPayment = (plans[indx]['price'])+(ded*1);
            //curval.activationCost = (plans[indx]['activation']*1)+(ded.act*1);
            curval.payAtClaimTime = value;
            this.setState(curval);
        }
        this.backLoad();
        //console.log(type+'--'+this.state[type]);
    }
    
    backLoad = () => {
        const { step2response,saveThirdStep, saveThirdStepResponse } = this.props;
        
        let uri = process.env.GATSBY_APP_API_BASE_URL+'rest/saveplan'+(global.dealertoken?'/?dealertoken='+global.dealertoken:'');
        let plans = this.state.plans;
        let indx = this.state.planIndex;
        
        var ded_code = 0;
        var tmpamt = this.state.payAtClaimTime;

       
        plans[indx]['deductibles'].forEach(function(tmp) {
            if((tmp.amt*1) === tmpamt){
                ded_code = tmp.id;
            }
        });
        
        let reqin = {
            keepVehicle: this.state.keepVehicle,
            levelOfCoverage: this.state.levelOfCoverage,
            payAtClaimTime: this.state.payAtClaimTime,
            termMonth: this.state.termMonth,
            milesPerMonthMin : this.state.values[0],
            milesPerMonthMax : this.state.values[1],
            monthlyPayment : this.state.monthlyPayment,
            activationCost : this.state.activationCost,
            covered : this.state.covered,
            rateId : plans[indx]['rateId'],
            recordId : step2response.recordId,
            deductibleCode : ded_code,
            deductible : ded_code,
            quote:0,
        }

        saveThirdStep(reqin);
        saveThirdStepResponse(reqin);
        //console.log(reqin)
        
        fetch(uri,{
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(reqin)
        });
            
    }

    nextStep = () => {
        const { step2response,saveThirdStepResponse,saveThirdStep,showLoader } = this.props;
        showLoader();
        let uri = process.env.GATSBY_APP_API_BASE_URL+'rest/saveplan'+(global.dealertoken?'/?dealertoken='+global.dealertoken:'');
        let plans = this.state.plans;
        let indx = this.state.planIndex;

        var ded_code = 0;
        var tmpamt = this.state.payAtClaimTime;
        plans[indx]['deductibles'].forEach(function(tmp) {
            if((tmp.amt*1) === tmpamt){
                ded_code = tmp.id;
            }
        });
                
        let reqin = {
            keepVehicle: this.state.keepVehicle,
            levelOfCoverage: this.state.levelOfCoverage,
            payAtClaimTime: this.state.payAtClaimTime,
            termMonth: this.state.termMonth,
            milesPerMonthMin : this.state.values[0],
            milesPerMonthMax : this.state.values[1],
            monthlyPayment : this.state.monthlyPayment,
            activationCost : this.state.activationCost,
            covered : this.state.covered,
            rateId : plans[indx]['rateId'],
            recordId : step2response.recordId,
            deductibleCode : ded_code,
            deductible : ded_code,
            quote:1,
        }
        fetch(uri,{
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify(reqin)
              })
            .then(res => res.json())
            .then((res) => {
                let step3 = {
                    "milesPerMonthMin": this.state.values[0],
                    "milesPerMonthMax": this.state.values[1],
                    "keepVehicle": this.state.keepVehicle,
                    "payAtClaimTime": this.state.payAtClaimTime,
                    "termMonth": this.state.termMonth,
                    "levelOfCoverage": this.state.levelOfCoverage,
                    "activationCost" : this.state.activationCost,
                    "monthlyPayment": this.state.monthlyPayment,
                    "planIndex": indx,
                    "covered": plans[indx]['covered'],
                    "userId": step2response.userId,
                    "vehicleId": step2response.vehicleId,
                    "recordId": step2response.recordId
                }
                saveThirdStepResponse(step3);
                saveThirdStep(step3)
                
                if (res.status ==='Success') {
                    if(global.dealertoken && global.href) this.ahrefElement.click();
                   navigate(`/createAccount/?q=${step2response.recordId}`+(global.dealertoken?'&dealertoken='+global.dealertoken:''));
                } else {
                   navigate("/vehicleInfo"+(global.dealertoken?'/?dealertoken='+global.dealertoken:''))
                }
                //hideLoader();
            });
    }

    startNewQoute = () => {
        this.props.clearAllSteps();
        navigate("/vehicleInfo"+(global.dealertoken?'/?dealertoken='+global.dealertoken:''))
    }

    closeOtherTabs = (props) => {
        setTimeout(() => {
            var y = document.getElementsByClassName("Collapsible__trigger is-open");
            for (let i = 0; i < y.length; i++) {
                if (!y[i].childNodes[0].className.includes(`selected${props.id}`)) {
                    y[i].click();
                }
            }
            let elementScroll = document.getElementsByClassName(`selected${props.id}`);
            elementScroll[0].scrollIntoView({ block: 'center', behavior: 'smooth'});
        }, 500);
        this.setState({ coveragePlan: props.header });
    }

    resetCustomizePlan = () => {
        //console.log('resetCustomizePlan')
        
    }
    async vinChanges(form, self, value) {
        if(value.length===17){
            let uri = process.env.GATSBY_APP_API_BASE_URL+'rest/getvindet?vinno='+value+(global.dealertoken?'&dealertoken='+global.dealertoken:'');
            await fetch(uri)
            .then(res => res.json())
            .then((result) => { 
                if(result.Year){
                    this.setState({ canSubmit: true });
                    this.setState({ showVehicalInfo: result.Year+' '+result.Make+' '+result.Model})
                } else {
                    self.refs[`${form}`].updateInputsWithError({
                    'vin': 'Sorry, Please enter correct VIN.'
                    });
                }
            });
        } else {
            this.setState({ canSubmit: false });
            self.refs[`${form}`].updateInputsWithError({
                'vin': 'Sorry, Please enter correct VIN.'
            });
        }
    };
    getPlan = async(vin) => {
        const { step1Data, step2response, hideLoader } = this.props;
        //console.log('step1Data--------------------');
        //console.log(step1Data);
          let uri = process.env.GATSBY_APP_API_BASE_URL+'rest/getPlanByData'+(global.dealertoken?'/?dealertoken='+global.dealertoken:'');
          
          step1Data.vehicleInfo.vin = vin;
          step1Data.vehicleInfo.vehicleId = step2response.vehicleId;
        
          //console.log(step1Data);
          
          fetch(uri,{
              method: 'POST',
              headers: {'Content-Type': 'application/json'},
              body: JSON.stringify(step1Data)
            })
          .then(res => res.json())
          .then((res) => {
            var plans = res.plans;
            if(plans.plan1){
                this.state.plans = plans;
                sessionStorage['plans'+(global.dealertoken?global.dealertoken:'')] = JSON.stringify(plans);
                
                var indx = this.state.planIndex;
                var tmpamt = this.state.payAtClaimTime;

                if(!plans[indx]){
                    indx = 'plan1';
                    this.setState({ planIndex: 'plan1' });
                }
                var ded=0;
                if(typeof plans[indx]['deduct'][tmpamt] !== 'undefined'){
                    ded = plans[indx]['deduct'][tmpamt];
                } else if(typeof plans[indx]['deduct'][250] !== 'undefined') {
                    this.setState({ payAtClaimTime: 250 });
                    ded = plans[indx]['deduct'][250];
                }

                let term = 0;
                //console.log(this.state.termMonth);
                if(plans[indx]['terms'][this.state.termMonth]){
                    term = plans[indx]['terms'][this.state.termMonth]*1;
                } else {
                    let term1 = Object.keys(plans[indx]['terms'])[0]; 
                    if(term1) {
                        this.setState({ termMonth: term1 });
                        term = plans[indx]['terms'][term1]*1;
                    }
                }

                //console.log(plans[indx]['price'],ded,term)
                //if(plans[indx]){
                    
                    this.setState({ levelOfCoverage: plans[indx]['name'] })
                    this.setState({ covered: 0 })
                    this.setState({ monthlyPayment: (plans[indx]['price'])+(ded*1)+term })
                    //this.setState({ activationCost: (plans[indx]['activation']*1)+(ded.act*1) })
                    this.setState({ covered: plans[indx]['covered'] })
                    this.setState({ VIN: vin });

                    this.backLoad();
                    hideLoader();
                //}
            } else {
                hideLoader();
                this.setState({ showalert: true });
            }
          });
    }
    submit = async(model) => {
        var { step2response,showLoader, saveFirstStep } = this.props;
        showLoader();
        //this.setState({showInfo:true})
        //console.log("current:"+this.state.monthlyPayment)
        this.setState({ covered: 0 })
        this.setState({ monthlyPayment:0});
        this.setState({ activationCost:0});
        //console.log('----submit----model-----')
        //console.log(model);
        let uri = process.env.GATSBY_APP_API_BASE_URL+'rest/updateVin'+(global.dealertoken?'/?dealertoken='+global.dealertoken:'');
            await fetch(uri,{
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({
                    vin: model.vin,
                    userId:step2response.userId,
                    recordId:step2response.recordId,
                    vehicleId:step2response.vehicleId
                })
              })
            .then(res => res.json())
            .then(async(res) => {
                //step1Data = res;
                saveFirstStep(res);
                //console.log('----step1Data---------')
                //console.log(step1Data)
                await this.getPlan(model.vin);
            });
    }

    onChangeTerm = async(key, val) => {
        this.setState({termMonth:key,termVal:val,monthlyPayment:val});
    }

    
    render() {
        const {buttonLoader } = this.props;
        const { canSubmit, covered, monthlyPayment, showalert,showInfo, plans,planIndex, showVehicalInfo, 
            keepVehicle, payAtClaimTime, levelOfCoverage, coveragePlan,VIN, termMonth } = this.state;
        var cded = {}
        var cterm = {};
        if(plans && plans[planIndex]){
            cded = plans[planIndex]['deduct'];
            cterm = plans[planIndex]['terms'];
            //if(payAtClaimTime)
            //console.log(termMonth,monthlyPayment);
        }
        
        const percentage = covered>0?covered:0;
        const CollapseHeader = (props) => (
            <div onClick={() => this.closeOtherTabs(props)} className={`collapse-header selected${props.id}`}>
                <img src={props.icon} alt="motor" />
                <h4 className="title">{props.header}</h4>
                <img className="arrow" src={arrowDown} alt="motor" />
            </div>
        );
        
        return (
            <Layout layoutType="main">
                <SEO title="Servicecontract.com - Customize Plan" keywords={[`servicecontract.com`, `service contracts`, `Extended warranty`]} />
                {global.dealertoken?
                <a className="hideme" id="price-step4" href={global.href+"#price-step4"} ref={ahref => this.ahrefElement = ahref} target="_top">trigger</a>
                :''}
                <div className="chat-content">
                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="26.496" viewBox="0 0 30 26.496">
                        <g id="Group_157" data-name="Group 157" transform="translate(-309.85 -157.354)">
                            <g id="Group_27" data-name="Group 27" transform="translate(309.85 157.354)">
                                <path id="Path_73" data-name="Path 73" d="M309.85,170.605c0,7.3,6.733,13.245,15,13.245a16.468,16.468,0,0,0,7.738-1.9,8.731,8.731,0,0,0,4.123.967,10.089,10.089,0,0,0,1.427-.1,1.14,1.14,0,0,0,.46-2.089,4.986,4.986,0,0,1-1.8-2.132,12.3,12.3,0,0,0,3.045-8c0-7.3-6.733-13.245-15-13.245S309.85,163.3,309.85,170.605Zm1.5,0c0-6.476,6.058-11.744,13.5-11.744s13.5,5.269,13.5,11.744a10.721,10.721,0,0,1-2.971,7.345.756.756,0,0,0-.153.772,6.667,6.667,0,0,0,1.795,2.7,7.254,7.254,0,0,1-4.044-.968.717.717,0,0,0-.386-.1.745.745,0,0,0-.373.1,14.929,14.929,0,0,1-7.37,1.911C317.409,182.349,311.351,177.081,311.351,170.605Z" transform="translate(-309.85 -157.354)" fill="#fff" />
                                <circle id="Ellipse_1" data-name="Ellipse 1" cx="1.924" cy="1.924" r="1.924" transform="translate(13.085 11.327)" fill="#fff" />
                                <circle id="Ellipse_2" data-name="Ellipse 2" cx="1.924" cy="1.924" r="1.924" transform="translate(17.943 11.327)" fill="#fff" />
                                <circle id="Ellipse_3" data-name="Ellipse 3" cx="1.924" cy="1.924" r="1.924" transform="translate(8.227 11.327)" fill="#fff" />
                            </g>
                        </g>
                    </svg>
                </div>
                {showalert?
                    <div className="success-container">
                        <div className="success-inner small">
                            <div onClick={() => navigate(`/vehicleInfo/?r=1`+(global.dealertoken?'&dealertoken='+global.dealertoken:''))} className="close-btn close">
                                <img className="" src={closeChat} alt="close" />
                            </div>

                            <div className="content">
                                <div className="subdiv">
                                <div className="subleft">
                                <img src={failImg} alt="fail" />
                                </div>
                                <div className="subright">
                                    <span>We’re sorry, we don’t have coverage in your vehicle and mileage.</span>
                                </div>
                                </div>
                                <div onClick={() => navigate(`/vehicleInfo/?r=1`+(global.dealertoken?'&dealertoken='+global.dealertoken:''))} className="button">
                                    <button className="btn">OK</button>
                                </div>
                            </div>
                        </div>
                    </div>
                :''}
                {showInfo?
                    <div className="success-container">
                        <div className="success-inner small">
                            <div onClick={() => this.setState({showInfo:false})} className="close-btn close">
                                <img className="" src={closeChat} alt="" />
                            </div>

                            <div className="content">
                            <div className="subdiv">
                                <div className="subleft">
                                    <img src={infoicon} alt="info" />
                                </div>
                                <div className="subright">
                                    <div>Just a heads up, your payment changed slightly now that you’ve given us your VIN.<br /><br /><br /></div>
                                    <button onClick={() => this.setState({showInfo:false})} className="btn small">OK</button>
                                </div>
                                
                            </div>
                            </div>
                        </div>
                    </div>
                :''}
                <section className={global.dealertoken?'grayish':''}>
                    <Wizard></Wizard>
                    <div className="custom-container vehinfo">

                        <div className="title-container">
                            <div className="icon-image">
                                <img src={customizeIcon} alt="icon" />
                            </div>
                            <div className="title-text">
                                <h1>Customize Your Plan</h1>
                                <p>We help you customize the plan to best fit your needs, driving habits, and budget.</p>
                            </div>
                            <div className="custom-dropDown-contain">
                                <div className="custom-dropDown" /* onClick={() => this.toggleVehicalInfo()} className={showVehicalInfo ? 'custom-dropDown open' : 'custom-dropDown'} */>
                                    <p>{showVehicalInfo}</p>
                                </div>
                            </div>
                        </div>

                        <div className="body-content inline customizePlan-container">
                            <div className="left-content">
                                <div className="amount-container mb-16">
                                    <div className="amount-inner">
                                        <h3>Your Custom Plan</h3>
                                        <div className="amt">
                                            <p>Monthly Payment</p>
                                            <h2><sup>$</sup>{monthlyPayment}</h2>
                                        </div>
                                        
                                        {percentage>0 &&
                                        <>
                                        <div className="circular-conainer">
                                            <p>Coverage</p>
                                            <div className={"c100 p"+percentage}>
                                                <span>{percentage}%</span>
                                                <div className="slice">
                                                    <div className="bar"></div>
                                                    <div className="fill"></div>
                                                </div>
                                            </div>
                                        </div>
                                        <p className="planhint">Coverage percentage is based on the total component parts in the What’s Covered tab</p>
                                        </>
                                        }

                                        {VIN ?
                                        <div className="fl100p">
                                        <button type="button" onClick={() => { this.nextStep() }} className="btn quote-btn" >Continue</button>
                                        </div>
                                        :
                                        <Formsy ref="planForm" onValidSubmit={this.submit} className="planvin" >
 {/* -----------My Form VIN-------------- */}                                       
                                            <div className="form-label-group bgtrans">
                                            <MyInputMask
                                                name="vin"
                                                id="vehvin"
                                                mask="*****************"
                                                valueChanges={(e) => this.vinChanges('planForm', this, e)}
                                                placeholder="Enter VIN *"
                                                className="form-control"
                                                validations={{maxLength: 17,minLength: 17}}
                                                value=""
                                                validationError="Please enter valid VIN."
                                                isLabelRequired={true}
                                                is
                                            />
                                            </div>
                                            <div className="row">
                                            <button type="submit" disabled={!canSubmit || buttonLoader} className={!canSubmit || buttonLoader ? 'btn disabled wizard-btn your-info-btn' : 'btn wizard-btn your-info-btn'}>
                                                <ButtonLoader isLoading={buttonLoader} label={'Get Exact Quote'} />
                                            </button> 
                                            </div>
                                            <div className="txtright">* Field is required</div>
                                        </Formsy>
                                        
                                        }
                                    </div>
                                </div>

                                <button type="button" onClick={() => this.startNewQoute()} className="btn outline quote-btn-2">START A NEW QUOTE</button>
                                {/* <Link to="/vehicleInfo" className="btn outline">START A NEW QUOTE</Link> */}
                                
                            </div>
                            <div className="right-content">
                                <div className="customize-container">
                                    <h4 className="ctitle mb-24">Customize the plan</h4>
                                    <div className="itemBox-container">
                                        {/* <div className="left">
                                            <p className="sbtitle">How long do you plan on keeping your vehicle?</p>
                                            <button onClick={() => this.onChangeQuestion('keepVehicle', 36)} className={keepVehicle === 36 ? 'active plan-btn' : 'plan-btn'}>36</button>
                                            <button onClick={() => this.onChangeQuestion('keepVehicle', 48)} className={keepVehicle === 48 ? 'active plan-btn' : 'plan-btn'}>48</button>
                                            <button onClick={() => this.onChangeQuestion('keepVehicle', 60)} className={keepVehicle === 60 ? 'active plan-btn' : 'plan-btn'} >60</button>
                                            <button onClick={() => this.onChangeQuestion('keepVehicle', 72)} className={keepVehicle === 72 ? 'active plan-btn' : 'plan-btn'} >72</button>
                                        </div>

                                        <div className="right"> */}
                                        {Object.keys(cded).length>0 &&
                                            <>
                                            <p className="sbtitle">How much do you want to pay when you have a claim?</p>
                                            {
                                                Object.keys(cded).map((k)=> {
                                                    return (
                                                        <button key={'ded'+k} onClick={() => this.onChangeQuestion('payAtClaimTime', (k*1))} className={payAtClaimTime === (k*1) ? 'active plan-btn' : 'plan-btn'}>${k}</button>
                                                    )
                                                })
                                            }
                                            </>
                                        }

                                        {Object.keys(cterm).length>0 &&
                                            <>
                                            <p className="sbtitle">How much do you want to pay for months?</p>
                                            {
                                                Object.keys(cterm).map((k)=> {
                                                    return (
                                                        <button key={'ctrm'+k} onClick={() => this.onChangeTerm((k*1),cterm[k]*1)} className={(termMonth*1) === (k*1) ? 'active plan-btn' : 'plan-btn'}>{k}</button>
                                                    )
                                                })
                                            }
                                            </>
                                        }
                                            {/* {plans && plans[planIndex]['deduct'][0]>-1000 ? <button onClick={() => this.onChangeQuestion('payAtClaimTime', 0)} className={payAtClaimTime === 0 ? 'active plan-btn' : 'plan-btn'}>$0</button> : '' }
                                            {plans && plans[planIndex]['deduct'][50]>-1000 ?<button onClick={() => this.onChangeQuestion('payAtClaimTime', 50)} className={payAtClaimTime === 50 ? 'active plan-btn' : 'plan-btn'} >$50</button>: '' }
                                            {plans && plans[planIndex]['deduct'][100]>-1000 ?<button onClick={() => this.onChangeQuestion('payAtClaimTime', 100)} className={payAtClaimTime === 100 ? 'active plan-btn' : 'plan-btn'} >$100</button>: '' }
                                            {plans && plans[planIndex]['deduct'][250]>-1000 ?<button onClick={() => this.onChangeQuestion('payAtClaimTime', 250)} className={payAtClaimTime === 250 ? 'active plan-btn' : 'plan-btn'} >$250</button>: '' } */}

                                        {/* </div> */}
                                    </div>

                                    <div className="itemBox-container last planlist">
                                        <p className="sbtitle">What level of coverage is the right fit for you?</p>
                                        
                                        {plans && plans.plan1 ? <button onClick={() => this.onChangeQuestion('levelOfCoverage', 'plan1')} className={levelOfCoverage === plans.plan1.name ? 'plan-btn active' : 'plan-btn'}>{plans.plan1.name} </button> :'' }
                                        {plans && plans.plan2 ? <button onClick={() => this.onChangeQuestion('levelOfCoverage', 'plan2')} className={levelOfCoverage === plans.plan2.name ? 'plan-btn active' : 'plan-btn'}>{plans.plan2.name} </button> :'' }
                                        {plans && plans.plan3 ? <button onClick={() => this.onChangeQuestion('levelOfCoverage', 'plan3')} className={levelOfCoverage === plans.plan3.name ? 'plan-btn active' : 'plan-btn'}>{plans.plan3.name} </button> :'' }
                                        {plans && plans.plan4 ? <button onClick={() => this.onChangeQuestion('levelOfCoverage', 'plan4')} className={levelOfCoverage === plans.plan4.name ? 'plan-btn active' : 'plan-btn'}>{plans.plan4.name} </button> :'' }
                                        
                                    </div>
                                    {covered &&
                                    <>
                                    <h4 className="ctitle mb-24">Get Covered</h4>
                                    <div className="collapse-container">
                                        {
                                            planData.map((pData, pKey) => {
                                                const icon = `planimg${pKey + 1}`;
                                                return (
                                                    <Collapsible key={pKey} trigger={<CollapseHeader header={pData.heading} id={pKey} icon={`${iconObj[icon]}`} />} >
                                                        {
                                                            pData.heading === coveragePlan &&
                                                            pData.innerData.map((data, key) => {
                                                                return (
                                                                    <div key={key} >
                                                                        <p className="sbtitle">{data.title}</p>
                                                                        <div className="inner-content">
                                                                            <ul className="custplan">
                                                                                {
                                                                                    data.data.map((d, k) => {
                                                                                        return (
                                                                                            <li key={k} >
                                                                                                {
                                                                                                    <img src={d[planIndex] ? rightmark : forbiddenIcon} alt="" className="mark" />
                                                                                                }
                                                                                                {d.name}
                                                                                            </li>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                        }

                                                    </Collapsible>
                                                )
                                            })
                                        }
                                    </div>
                                    </>
                                    }
                                </div>
                            </div>

                        </div>
                    </div>
                </section>
            </Layout>
        )
    }
}

const mapStateToProps = state => {
    return {
        pageLoader: state.primary.pageLoader,
        buttonLoader: state.primary.buttonLoader,
        step1Data: state.register.step1,
        step2Data: state.register.step2,
        step3Data: state.register.step3,
        step2response: state.register.step2response,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        showLoader: () => dispatch(actionCreators.showLoader()),
        showButtonLoader: () => dispatch(actionCreators.showButtonLoader()),
        hideLoader: () => dispatch(actionCreators.hideLoader()),
        hideButtonLoader: () => dispatch(actionCreators.hideButtonLoader()),
        saveThirdStep: (formData) => dispatch(actionCreators.saveThirdStep(formData)),
        saveFirstStep: (formData) => dispatch(actionCreators.saveFirstStep(formData)),
        saveThirdStepResponse: (response) => dispatch(actionCreators.saveThirdStepResponse(response)),
        saveSecondStep: (formData) => dispatch(actionCreators.saveSecondStep(formData)),
        saveSecondStepResponse: (response) => dispatch(actionCreators.saveSecondStepResponse(response)),
        saveFourthStep: (formData) => dispatch(actionCreators.saveFourthStep(formData)),
        clearAllSteps: () => dispatch(actionCreators.ClearAllSteps())
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomizePlanPage);